<template>
  <div class="file-item d-flex justify-content-between align-items-center">
    <img svg-inline :src="url" alt="xml" />
    <div class="file-info d-flex flex-column align-items-start w-100 ml-3">
      <small class="text-dark-darken">{{ file.upload.filename }}</small>
      <div class="progress-bar bg-transition flex-1 my-1 w-100 dz-progress" v-if="!error">
        <div
          class="progress dz-upload"
          :id="`file-item-${file.upload.uuid}`"
          :style="{ width: `${progress}%` }"
        ></div>
      </div>
      <a v-else href="#" class="text-warning fail font-weight-bold"
        >Falha ao subir o arquivo - Arquivo inválido</a
      >
      <div class="d-flex justify-content-between align-items-center w-100">
        <small class="text-dark-darken">{{ file.upload.total / 1000 }} KB</small>
        <transition name="fade" mode="out-in">
          <small class="text-primary font-weight-bold" v-if="success">{{
            $t('requestItems.fileSendingCompleted')
          }}</small>
        </transition>
      </div>
    </div>
    <close-button
      color="var(--transition)"
      class="ml-3"
      v-if="!error && !success"
      style="width: 78px;"
      :handleClick="removeFile"
    />
    <transition name="fade" mode="out-in">
      <div
        class="actions d-flex justify-content-start align-items-center ml-3 fixed-width"
        v-if="error || success"
      >
        <close-button color="var(--primary)" v-if="error" :handleClick="removeFile" />
        <close-button icon="check" color="var(--success)" v-if="success" class="cursor-initial" />
        <close-button icon="delete" color="var(--transition)" v-if="success" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'file-item',
  props: {
    file: {
      type: File,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    dropzoneRef: {
      type: Object,
    },
  },
  data() {
    return {
      error: false,
      success: false,
      progress: 0,
    };
  },
  computed: {
    url() {
      // eslint-disable-next-line
      const icon = require(`@/assets/images/icon-${this.type}.svg`);
      return icon;
    },
  },
  methods: {
    removeFile() {
      this.dropzoneRef.removeFile(this.file);
    },
  },
};
</script>

<style lang="scss">
.file-item {
  & + .file-item {
    margin-top: 15px;
  }

  .fixed-width {
    width: 78px;
  }
  .fail {
    text-decoration: underline;
    font-size: 10px;
    display: block;
  }

  .progress-bar {
    height: 4px;

    .progress {
      background-color: var(--primary);
      width: 0;
      transition: width 500ms ease;
    }
  }
}
</style>
